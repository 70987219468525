import css from 'styled-jsx/css'
export const ErrorComponentTypes = css`
  .err-page {
    padding: 60px 0;
    text-align: center;
    height: calc(100vh - var(--layout-header-height));
  }
  .err-page .image img {
    max-width: 100%;
  }

  .err-page .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .err-page .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 64px;
  }
  .err-page .navigate-button-group {
    justify-content: center;
    max-width: 500px;
    min-width: 300px;
  }
  .mr-4 {
    margin-right: 28px;
  }
`
