import React, { FC } from 'react'
import Head from 'next/head'
import ErrorPage from '../../components/ErrorPage'
import { useTranslation } from '../../hooks/UserTranslation'

const Page404: FC = () => {
  const { translate } = useTranslation()
  return (
    <div>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <title>{translate('meta:page_does_not_exist')}</title>
      </Head>
      <ErrorPage
        urlImg="/img/404.png"
        title={translate('public_key:page_does_not_exist')}
        content={translate('public_key:we_coundt_find_what_you_looking_for')}
      />
    </div>
  )
}

export default Page404
