import React, { FC } from 'react'
import Image from 'next/image'
import { LINK_URL_CHAT_WITH_SUPPORT, LINK_URL_ROOT } from '../../utilities/LinkURL'
import { ErrorComponentTypes } from '../../style/styled/ErrorComponent.style'
import { useTranslation } from '../../hooks/UserTranslation'
import { Col, Row } from '../share/ANTD/Grid'
import { Button } from '../share/ANTD/Button'
import { useAppRouter } from '../../hooks/UseAppRouter'

const ErrorPage: FC<{ urlImg: string; title: string; content: string }> = ({ urlImg, title, content }) => {
  const { translate } = useTranslation()
  const router = useAppRouter()
  const goHomePage = () => {
    router.push(LINK_URL_ROOT)
  }
  return (
    <div className="wrapper-container">
      <style jsx>{ErrorComponentTypes}</style>
      <div className="err-page">
        <Row justify="center" align="middle" className="h-100">
          <Col xs={24} md={14}>
            <div className="image">
              <Image src={urlImg} alt="background" width={444} height={270} />
            </div>
            <p className="title">{title}</p>
            <p className="sub-title">{content}</p>

            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex navigate-button-group">
                <div className="mr-4">
                  <Button
                    onClick={() => {
                      router.push(LINK_URL_CHAT_WITH_SUPPORT)
                    }}>
                    {translate('button:error_page_contact_us')}
                  </Button>
                </div>

                <Button type="primary" className="" onClick={goHomePage}>
                  {translate('button:go_to_home_page')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ErrorPage
